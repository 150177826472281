.card-register {
  background-color: #395a9d;
  border-radius: 8px;
  color: #fff;
  max-width: 750px;
  margin: 20px 0;
  //min-height: 400px;
  padding: 48px;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    color: $primary-color;
    text-align: center;
  }
  & .btn {
    margin-top: 24px;
  }
  & .forgot {
    text-align: center;
  }

  .social-line {
    & .btn {
      margin: 0 12px 48px;
    }
  }

  .input-group {
    margin-bottom: 24px;
  }
  
  .input-group-text {
    border: 1px solid $white-color;
  }
}
