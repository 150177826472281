// @import "master/variables";
// @import "master/mixins";

// // Plugins CSS
// // @import "master/plugins/plugin-bootstrap-switch";
// // @import "master/plugins/plugin-nouislider";
// // @import "master/plugins/plugin-datepicker";

// // Core CSS
// @import "master/buttons";
// @import "master/inputs";
// // @import "master/checkbox-radio";
// // @import "master/progress-bars";
// // @import "master/badges";
// // @import "master/pagination";
// @import "master/typography";
// @import "master/misc";
// // @import "master/labels";

// // components
// // @import "master/nucleo-icons";
// // @import "master/social-buttons";
// @import "master/tabs-navs";
// @import "master/navbars";
// // @import "master/dropdown";
// // @import "master/alerts";
// @import "master/images";
// // @import "master/tooltips-and-popovers";
// // @import "master/modal";
// // @import "master/icons";
// // @import "master/carousel";
// @import "master/footers";

// // Cards
// @import "master/cards";
// @import "master/cards/card-plain";
// @import "master/cards/card-register";
// @import "master/cards/card-profile";

// // example pages and sections
// @import "master/examples";
// @import "master/sections";


// @import "master/responsive";

// // React Differences
// @import "react/react-differences.scss";

// // React Audio Player
// @import "react-h5-audio-player/src/styles.scss";

// // CNWM Extensions
// @import "cnwm.scss";


// **** Light Theme ****
@media screen and (prefers-color-scheme: light) {
  // Variables
  @import "master/variables-light";
  @import "master/mixins";

  // Core CSS
  @import "master/buttons";
  @import "master/inputs";
  @import "master/typography";
  @import "master/misc";

  // Components
  @import "master/tabs-navs";
  @import "master/navbars";
  @import "master/images";
  @import "master/modal";
  @import "master/carousel";
  @import "master/footers";

  // Cards
  @import "master/cards";
  @import "master/cards/card-plain";
  @import "master/cards/card-register";
  @import "master/cards/card-profile";

  // Pages and sections
  @import "master/examples";
  @import "master/sections";
  @import "master/responsive";

  // React differences
  @import "react/react-differences.scss";

  // React Audio Player
  //@import "react-h5-audio-player/src/styles.scss";
  @import "h5-audio-player.scss";

  // CNWM extensions
  @import "cnwm.scss";
}
// *********************


// **** Dark Theme ****
@media screen and (prefers-color-scheme: dark) {
  // Variables
  @import "master/variables-dark";
  @import "master/mixins";

  // Core CSS
  @import "master/buttons";
  @import "master/inputs";
  @import "master/typography";
  @import "master/misc";

  // Components
  @import "master/tabs-navs";
  @import "master/navbars";
  @import "master/images";
  @import "master/modal";
  @import "master/carousel";
  @import "master/footers";

  // Cards
  @import "master/cards";
  @import "master/cards/card-plain";
  @import "master/cards/card-register";
  @import "master/cards/card-profile";

  // Pages and sections
  @import "master/examples";
  @import "master/sections";
  @import "master/responsive";

  // React differences
  @import "react/react-differences.scss";

  // React Audio Player
  //@import "react-h5-audio-player/src/styles.scss";
  @import "h5-audio-player.scss";

  // CNWM extensions
  @import "cnwm.scss";
}
// *********************
