// Modal sizes
.modal-dialog {
  width: 80%;
  max-width: 1024px;
}

// Research menu highlight fix
.navbar-translate .navbar-brand[data-special="true"],
.navbar-collapse .navbar-nav > .nav-item > .nav-link[data-special="true"] {
    color: #00aea4 !important;

  &:hover, &:focus{
      color: $default-color !important;
      text-decoration: none;
  }
}
.navbar-transparent .navbar-brand[data-special="true"],
[class*="bg"] .navbar-brand[data-special="true"] {
  color: lighten(#00aea4, 10%) !important;

  &:hover, &:focus{
      color: $white-color !important;
      text-decoration: none;
  }
}

// Header width fix
.container-fluid {
  padding-left: 32px;
  padding-right: 32px;
}

// Footer fix
.row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-around;
}

// Default portrait (mobile / tablet)
//@media screen and (max-width: 575px) {
@media screen and (max-width: 767px) {
  .navbar .navbar-brand {
    padding: 8px 15px 0 0;
  }

  .navbar .navbar-toggler {
    padding: 16px 15px 16px 0;
  }

  .navbar .navbar-toggler .navbar-toggler-bar {
    margin: 0 0 0 auto;
  }

  .navbar-translate {
    display: grid;
    grid-template-columns: auto auto;
    padding: 0 24px 0 0;
    justify-content: end !important;
    text-align: right;
  }

  .container {
    max-width: inherit;
  }

  // Modal sizes
  .modal-dialog {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-body {
    padding: 20px;
  }
}

// Remove audio module border
.rhap_container {
  box-shadow: none;
}

// Social buttons in contact form
.social-line .btn-round {
  min-width: 130px;
}

@media screen and (max-width: 550px) {
  .social-line .btn-round {
    min-width: 120px;
  }
}

// Partner image sizes
.partner-image {
  max-height: 200px;
  max-width: 180px;
  margin: 12px;
}

.partner-image-big {
  max-height: 400px;
  max-width: 400px;
  margin: 12px;
}

@media screen and (max-width: 666px) {
  .partner-image-big {
    max-height: 290px;
    max-width: 290px;
    margin: 0;
  }  
}

// Table font colour
thead, tbody {
  color: $font-color;
}
.table td, .table th {
  border-top: 0;
}
.table-hover tbody tr:nth-of-type(even) {
  background-color: $background-color;
}
.table-hover tbody tr:nth-of-type(odd) {
  background-color: $background-color-light;
}
.table-striped tbody tr:hover {
  background-color: $background-color-light-2x;
  color: $font-color;
}
.table-striped tbody tr:hover {
  background-color: $background-color-light-2x;
  color: $font-color;
}

.darkmode-toggle {
  background-color: transparent;
  color: $font-color;
  border: 0;
  padding: 16px;
  line-height: 1;
  @include opacity(.8);

  &:focus,
  &:hover{
      @include opacity(1);
  }
}

.navbar-transparent .darkmode-toggle {
  color: #fff;
}

// YouTube
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

// Header - Default image for portrait (mobile / tablet)
.cnwm-header {
  background-image: url("../img/cnw-bg-sml.webp");
}

// Header - Default image for landscape (mobile / tablet)
@media screen and (min-width: 667px) and (max-height: 500px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1024 x 768
@media screen and (min-width: 1016px) and (max-height: 768px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1280 x 960
@media screen and (min-width: 1272px) and (max-height: 960px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1400 x 1050
@media screen and (min-width: 1392px) and (max-height: 1050px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1440 x 1080
@media screen and (min-width: 1432px) and (max-height: 1080px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1600 x 1200
@media screen and (min-width: 1592px) and (max-height: 1200px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1600 x 1200
@media screen and (min-width: 1592px) and (max-height: 1200px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1920 x 1440
@media screen and (min-width: 1912px) and (max-height: 1440px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 1856 x 1392
@media screen and (min-width: 1848px) and (max-height: 1392px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 2000 x 1500
@media screen and (min-width: 1992px) and (max-height: 1500px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 2400 x 1800
@media screen and (min-width: 2392px) and (max-height: 1800px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 2800 x 2100
@media screen and (min-width: 2792px) and (max-height: 2100px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}

// Header - 3200 x 2400
@media screen and (min-width: 3192px) and (max-height: 2400px) {
  .cnwm-header {
    background-image: url("../img/cnw-bg.webp");
  }
}
